<template>
  <div>
    <h3>Observational backing</h3>
    <p>
      Michael Faraday during his experiments noticed something very
      very unusual. He had a ring on whose one half was wound a wire
      that carried current while the other half had a wire wound around it too
      but it wasn't attached to any source of current. But still, whenever the current
      was passed through the wire in the first half, some current also passed through
      the wire in the second half. This wireless "transfer" of current would arouse curiosity
      in anyone. After some playing around, Faraday discovered more properties of this
      "transfer" which is now called induction and published his working. Another contemporary
      of Faraday, Joseph Henry also discovered this same phenomenon and worked on this independently,
      but Faraday gets the credit because his work was published earlier than that of Henry's.
    </p>
    <h3>Faraday's Law</h3>
    <p>
      The law states that <i>"E.M.F induced in a coil is directly propotional to the negative of the rate of change of
        magnetic flux through the coil" </i>
      <br> Mathematically, if a coil has \(N\) turns,
      $$\epsilon =-N\frac{d\phi}{dt}$$
      This animation from <a href="https://upload.wikimedia.org/wikipedia/commons/7/72/Electromagnetic_induction_-_solenoid_to_loop_-_animation.gif">Wikipedia</a>
      explains the concept
      <br>
      <img src="https://upload.wikimedia.org/wikipedia/commons/7/72/Electromagnetic_induction_-_solenoid_to_loop_-_animation.gif" style="width: 25%; height: 25%;">
    </p>
    <h3>Lenz's Law</h3>
    <p>
      Lenz's law states that, <i>"The emf induced in a coil is such that the current produced by it would
        produce a magnetic field such that it would oppose the change in flux that caused the induction." </i>
      <br>
      <br>
      As an example, consider a coil in the x-y plane with a magnetic field in the -z direction(into the plane-which is denoted by a cross) that is increasing with time.
      This magnetic field would now produce a counterclockwise current in the coil because the magnetic field due
      to the new induced current would be opposite to the initial magnetic field.
      <br>
      <img src="Lenz's Law.png" style="width: 50%; height: 50%;">
    </p>
    <h3>Is Faraday's law incomplete?</h3>
    <p>
      After looking at Lenz's law and what most teachers teach is that we use Faraday's law to find the
      <i>magnitude</i> of the induced emf and we use Lenz's law to find the <i>direction</i> of the induced current.
      This would lead you to think that Faraday's law is incomplete as it does not provide you the direction of the induced
      current. This isn't true. Notice the minus sign in the mathematical statement of Faraday's law. That negative
      sign takes care of the direction and indicates that the induced emf will produce a current that will oppose the
      original change in magnetic flux through the coil.
    </p>
    <h3>Some more analysis</h3>
    <p>
      Magnetic flux is defined as:
      $$\Phi=\textbf{B}\cdot \textbf{A}= BAcos\theta$$
      Where, \(\textbf{A}\) is the area vector which has direction perpendicular to the coil surface and
      \(\theta\) is the angle between the area vector and the magnetic field vector.
      <br> Using this in Faraday's law gives us four key points to keep in mind:
      <br>The induced emf is non zero if:
      <ol>
        <li>Either the magnetic field changes with time</li>
        <li>or the Area changes with time</li>
        <li>or if \(\theta\) changes with time</li>
        <li>or if any two or more of these change with time</li>
      </ol>
    </p>
    <v-responsive>
      <v-layout align-center justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Mag',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Faraday\'s Law';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a lens?', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Ray Diagrams', img:'/assets/number-2.svg', action: () => this.goto('ja')},
      {title: 'Lens Formula', img:'/assets/number-3.svg', action: () => this.goto('ka')},
      {title: 'MagicGraph' ,img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Magnetism',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to use ray diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
