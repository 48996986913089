import {
  makeResponsive,
  //makeResponsive(brd1);
  placeTitle,
  //placeTitle(brd1,'title', 'subtitle');
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  //placeArrow(board, pt1, pt2, width, cols)
  placeGravity,
  placeText,
  //placeText(board, positionX, positionY, message)
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeSliderSwitch,
  placeBoldText,
  //placeBoldText(brd6, -9, 1, 'Liquid');
  placeDash,
  // placeDash(board, [x1, y1], [x2, y2], width, cols)
  placeRightText,
  placeLabel
} from '../../../common/edliy_utils-energy';
const Boxes = {
    box1: function () {
    //Create the Board
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-2, 11, 12, -3],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
   //suspend updates
    brd1.suspendUpdate();
    // Layering
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    //Set CSS
    brd1.options.text.cssDefaultStyle = 'fontFamily:Oswald;'
    brd1.options.text.highlightCssDefaultStyle = 'fontFamily:Oswald';
    //Set Highlight
    brd1.options.text.highlight = false;
    brd1.options.line.highlight=false;
    brd1.options.arrow.highlight=false;
    brd1.options.point.highlight=false;
    brd1.options.arc.highlight=false;
//   Fixing
    brd1.options.text.fixed=true;
//    JXG.Options.point.fixed=true;
    brd1.options.layer['image']=17;
    brd1.options.layer['text']=16;
    //brd1.options.layer['line']=14;
    brd1.options.layer['point']=15;
    brd1.options.layer['glider']=15;
    //Make the board responsive
    makeResponsive(brd1);
    // Creat titleStyle
    placeTitle(brd1, 'Electric Flux', 'Magnetic Flux and Electromotive Force');
    //dragMe(brd1, 5, 5);
    //var xaxis = brd1.create('line',[[0,0], [0.5, 0]],{visible:false});
    //Title and tetxs
    //var title1 = brd1.create('text', [5, 8.5, "<b> Faraday's Law of Induction <b>"],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(26*brd1.canvasWidth/500.)}},);
    //Title and tetxs
    var title1 = brd1.create('text', [5, 10, '<b> Electric Flux <b>'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)}},);
    var title2 = brd1.create('text', [5, 9, 'Drag the charges into the field'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);

    var txt1 = brd1.create('text', [3.2, 7.5, 'q > 0'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);
    var txt2 = brd1.create('text', [7.2, 7.5, 'q < 0'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}},);

    var ymax = 5;
    var xmax = 10;
    var sizeImg = 0.2; //Put the half of the size of the image

    //Polygon
    var polygon = brd1.create('polygon', [[-1, -1], [xmax+1, -1], [xmax+1, ymax+1], [-1, ymax+1]], {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: '#949494', strokeWidth: 3}, fillColor: '#D9D9D9', fillOpacity:1});

    //Charges
    var ChargesQ=[]
    var ChargesP=[]

    for(let i=0; i <= 100; i++){

        var p_val = brd1.create('image', ['assets/positiveCharge.svg', [2.3, 7.3], [0.4, 0.4]], {visible: true, fixed: false});
        //var p_val = brd1.create('point', [2.5, 7.5],{face:'o' , name:'', size:5, color: '#FC3C3C', withLabel:true, fixed:false, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
        var q_val = 10;
        ChargesP.push(p_val);
        ChargesQ.push(q_val);

    }

    for(let i=0; i <= 100; i++){

        var p_val = brd1.create('image', ['assets/negativeCharge.svg', [6.3, 7.3], [0.4, 0.4]], {visible: true, fixed: false});
        //var p_val = brd1.create('point', [6.5, 7.5],{face:'o' , name:'', size:5, color: '#323BB3', withLabel:true, fixed:false, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
        var q_val = -10;
        ChargesP.push(p_val);
        ChargesQ.push(q_val);
    }

    //Points in plane where to show the electric field
    var points1 = [];

    for(let y=0; y <= ymax; y++){

        var pVector1 = [];

        for(let x=0; x<= xmax; x++){

            var pt1 = brd1.create('point', [x, y],{face: 'o', name: '', size:0.1, color: 'red', fixed:true, visible:false, label:{offset: [10, 15], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
            var pt2 = brd1.create('point', [function(){return electricField(x,y)[0];}, function(){return electricField(x,y)[1];}] ,{face: 'o', name: '', size:0.1, color: 'black', fixed:true, visible:false, label:{offset: [10, 15], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

            var arrows = brd1.create('segment', [pt1, pt2], {fixed: true, strokeColor: '#464B6E', visible:true, strokeWidth: 1.5,
                                                    highlightStrokeWidth: 2,
                                                    lastArrow: {type: 2, size: 8, highlightSize: 6},
                                                    touchLastPoint: false,
                                                    firstArrow: {type: 2, size: 0}
            });

            pVector1.push(pt1);
        }

        points1.push(pVector1);
    }

    function electricField(x,y){

        var EFx=0; //Intensity of the electric field in y
        var EFy=0; //Intensity of the electric field on x
        var EFi=0; //Intensity of the electric field

        for (let i=0; i<ChargesP.length; i++){

            if(ChargesP[i].Y() <= 6 && ChargesP[i].Y() >= -1 ){

                k= 2*1/(4*Math.PI*8.854);
                r = Math.sqrt(Math.pow((x-(ChargesP[i].X() + sizeImg)),2) + Math.pow((y-(ChargesP[i].Y() + sizeImg)),2))
                EFi = k*ChargesQ[i]/(r*r);

                var alpha = Math.atan((y-(ChargesP[i].Y() + sizeImg))/(x-(ChargesP[i].X() + sizeImg)));
                if((x - (ChargesP[i].X() + sizeImg) < 0)){
                    alpha = Math.PI + alpha;
                }
                var ex = EFi * Math.cos(alpha) ;
                var ey = EFi * Math.sin(alpha) ;

                EFx = EFx + ex;
                EFy = EFy + ey;
            }
        }
        return [EFx +x,EFy +y];
    }

        brd1.unsuspendUpdate();

                      },
              }
export default Boxes;
